import * as React from 'react';
import ReactPlayer from 'react-player';
import PlayIcon from 'assets/icons/play.svg';
import PauseIcon from 'assets/icons/pause.svg';

/**
 * @todo document this component
 */
class VideoThumbnail extends React.Component {
	state = {
		isPlaying: false,
	};

	videoContainer;

	constructor(props) {
		super(props);

		this.state = {
			isPlaying: true,
		};

		this.videoContainer = React.createRef();
	}

	togglePlayback = () => {
		if (this.state.isPlaying === true) {
			this.setState({
				isPlaying: false,
			});
		} else {
			this.setState({
				isPlaying: true,
			});
		}
	};

	render() {
		return (
			<div className={`video-thumbnail ${this.props.parentClass}`} ref={this.videoContainer}>
				<ReactPlayer
					url={this.props.fileSrc}
					playing={this.state.isPlaying}
					loop
					className="video-wrap"
					playsinline
					muted
					width="100%"
					height="0"
				/>

				<button
					className={`video-thumbnail__controls ${
						this.state.isPlaying ? 'show-pause' : 'show-play'
					}`}
					onClick={(e) => {
						e.preventDefault();
						this.togglePlayback();
					}}
				>
					{this.state.isPlaying ? <PauseIcon /> : <PlayIcon />}
				</button>
			</div>
		);
	}
}

export default VideoThumbnail;
