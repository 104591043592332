import React from 'react';
import { Link } from 'components/Html';
import ChevronRight from 'assets/icons/chevron-right.svg';
import { __ } from '@wordpress/i18n';
import { addQueryArgs } from '@wordpress/url';
import noop from 'utils/noop';

/**
 * Pagination component.
 */
const Pagination = (props) => {
	const { basePath = '/' } = props;
	const currentPage = parseInt(props.currentPage, 10);
	const totalPages = parseInt(props.pages, 10);
	const onClickHandler = props.onClick || noop;

	const prevPage = currentPage > 1 ? currentPage - 1 : null;
	const nextPage = currentPage < totalPages ? currentPage + 1 : null;

	const pages = () => {
		const current = parseInt(props.currentPage, 10);
		const last = parseInt(props.pages, 10);

		const delta = 3;
		const left = current - delta;
		const right = current + delta + 1;
		const range = [];
		const rangeWithDots = [];

		let l;

		for (let i = 1; i <= last; i++) {
			if (i === 1 || i === last || (i >= left && i < right)) {
				range.push(i);
			}
		}

		for (const i of range) {
			if (l) {
				if (i - l === 2) {
					rangeWithDots.push(l + 1);
				} else if (i - l !== 1) {
					rangeWithDots.push('...');
				}
			}
			rangeWithDots.push(i);
			l = i;
		}

		return rangeWithDots;
	};

	const getHref = (pageNum) => {
		const page = Number(pageNum);
		let url = page === 1 ? basePath : `${basePath}page/${page}`;
		if (props.queryArgs) {
			url = addQueryArgs(url, props.queryArgs);
		}

		return url;
	};

	if (totalPages < 2) {
		return null;
	}

	return (
		<div className="pagination">
			{prevPage && (
				<Link
					key="previous"
					className="previous"
					href={getHref(prevPage)}
					onClick={onClickHandler}
				>
					<ChevronRight aria-hidden="true" />
					{__('Previous', 'starbucks')}
				</Link>
			)}

			<ul>
				{pages().map((page, i) => {
					if (page === currentPage) {
						return <li key={`current-${page}`}>{page}</li>;
					}
					if (page === '...') {
						return <li key={`...-${i}`}>&hellip;</li>;
					}
					return (
						<li key={page}>
							<Link href={getHref(page)} onClick={onClickHandler}>
								{page}
							</Link>
						</li>
					);
				})}
			</ul>

			{nextPage && (
				<Link key="next" className="next" href={getHref(nextPage)} onClick={onClickHandler}>
					{__('Next', 'starbucks')}
					<ChevronRight aria-hidden="true" />
				</Link>
			)}
		</div>
	);
};

export default Pagination;
