import { forwardRef, createElement } from 'react';
import DOMPurify from 'isomorphic-dompurify';

// Tags.
const A = 'a';
const IFRAME = 'iframe';
const IMG = 'img';
const TABLE = 'table';
const TD = 'td';

// Attributes.
const ALLOW = 'allow';
const ALT = 'alt';
const ALLOWFULLSCREEN = 'allowfullscreen';
const CLASS = ['class', 'className'];
const CELLSPACING = 'cellspacing';
const CELLPADDING = 'cellpadding';
const COLSPAN = 'colspan';
const DOWNLOAD = 'download';
const FRAMEBORDER = 'frameborder';
const FULLSCREEN = 'fullscreen';
const HEIGHT = 'height';
const HREF = 'href';
const ITEM_PROP = ['itemprop', 'itemProp'];
const REL = 'rel';
const ROLE = 'role';
const ROWSPAN = 'rowspan';
const SRC = 'src';
const STYLE = 'style';
const TARGET = 'target';
const TITLE = 'title';
const WIDTH = 'width';

const UNIVERSALLY_SAFE_ATTRIBUTES = [
	'aria-*',
	...CLASS,
	HEIGHT,
	...ITEM_PROP,
	ROLE,
	STYLE,
	WIDTH,
	'id',
];

const getAllowedAttributes = () => {
	return {
		'*': [...UNIVERSALLY_SAFE_ATTRIBUTES],
		[A]: [...UNIVERSALLY_SAFE_ATTRIBUTES, DOWNLOAD, HREF, REL, STYLE, TARGET, TITLE],
		[IFRAME]: [
			...UNIVERSALLY_SAFE_ATTRIBUTES,
			ALLOWFULLSCREEN,
			FRAMEBORDER,
			ALLOW,
			FULLSCREEN,
			SRC,
		],
		[IMG]: [...UNIVERSALLY_SAFE_ATTRIBUTES, ALT, HEIGHT, HREF, SRC, WIDTH],
		[TABLE]: [...UNIVERSALLY_SAFE_ATTRIBUTES, CELLPADDING, CELLSPACING],
		[TD]: [...UNIVERSALLY_SAFE_ATTRIBUTES, COLSPAN, ROWSPAN],
	};
};

/**
 * Component for unescaped HTML.
 */
const RawHTML = forwardRef(({ value, tagName, ...props }, ref) => {
	const allowedAttributes = getAllowedAttributes();

	const allowedTagAttributes =
		tagName in allowedAttributes
			? [...allowedAttributes['*'], ...allowedAttributes[tagName]]
			: allowedAttributes['*'];

	return createElement(tagName, {
		dangerouslySetInnerHTML: {
			__html: DOMPurify.sanitize(value, { ADD_ATTR: [TARGET] }),
		},
		...Object.keys(props).reduce((sanitizedAttributes, propKey) => {
			if (allowedTagAttributes.includes(propKey)) {
				return {
					...sanitizedAttributes,
					[propKey]: props[propKey],
				};
			}

			return sanitizedAttributes;
		}, {}),
		ref, // Forward the ref.
	});
});

export { RawHTML };
