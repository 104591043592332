import React, { Component } from 'react';
import LoadingIcon from 'assets/icons/loading.svg';

/**
 * @todo document this component
 */
export class Loading extends Component {
	render() {
		return (
			<div
				className="loading"
				style={{
					height: '100%',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<LoadingIcon fill={this.props.fill || 'black'} />
			</div>
		);
	}
}
