import * as React from 'react';
import { Loading } from 'components/Animation/Loading';
import PostCard from './Blogroll/PostCard';

export class CardGrid extends React.Component {
	/**
	 * Returns the proper category to display for the post
	 *
	 * If a category is passed to this component via 'category' prop, it will always take precedent so that we can
	 * always show a certain category (like in the category based grid on the homepage or an archive page).
	 *
	 * Otherwise, we start to fall back to primary categories as set by yoast on the various content types.
	 *
	 * @param story
	 * @returns {*}
	 */
	getCategory = (story) => {
		if (this.props.category) {
			return this.props.category;
		}

		if (this.props.terms && story.primary_term) {
			return this.props.terms[story.primary_term];
		}
	};

	render() {
		const { stories, context, disablePostImages } = this.props;

		let customGridClass = '';

		if (context === 'originals') {
			customGridClass = ' post-grid--narrow post-grid--gutter-sm';
		}

		return (
			<div className={`sb-card-grid post-grid${customGridClass}`}>
				{!stories && <Loading />}
				{!!stories &&
					stories.map((story) => (
						<PostCard
							story={story}
							disableImage={disablePostImages}
							key={story.id}
							primaryTerm={this.getCategory(story)}
							context={context}
							showTerm={this.props.showTerms}
							enforceNoMedia={this.props.enforceNoMedia || false}
						/>
					))}
			</div>
		);
	}
}

export default CardGrid;
