import * as React from 'react';
import Image from 'next/image';
import { longDateShortMonthFormat } from 'utils/date';
import { Link } from 'components/Html';
import VideoThumbnail from 'components/Images/VideoThumbnail';
import { RawHTML } from 'components/Html/Raw/html';

export class PostCard extends React.Component {
	static decorateClasses(context) {
		if (!context) {
			return {
				titleClass: '',
				titleContainClass: '',
			};
		}

		return {
			titleClass: ` sb-card-grid-item__title--${context}`,
			titleContainClass: ` sb-card-grid-item__title-contain--${context}`,
		};
	}

	postDate = () => {
		const datePosted = this.props.story.date;
		const postDate = longDateShortMonthFormat(datePosted);

		return (
			<p className="post-grid-item__published">
				<time itemProp="datePublished" content={datePosted}>
					{postDate}
				</time>
			</p>
		);
	};

	render() {
		const { story, context, disableImage, showTerm, enforceNoMedia = false } = this.props;
		const { titleClass, titleContainClass } = PostCard.decorateClasses(context);
		const { hide_date: hideDate } = story;

		const image = story.image?.src ? story.image : null;

		const primaryTerm = showTerm && story.primary_term?.name ? story.primary_term : null;

		// add exception for style if press/views page
		const isPressViews = story.primary_term?.slug === 'views';

		const gridClass =
			context === 'originals'
				? 'post-grid-item--gutter-sm post-grid-item-sm-50'
				: 'post-grid-item-sm-50 post-grid-item-md-33';

		const videoThumb = story.video_thumbnail;

		return (
			<div
				className={`sb-card-grid-item post-grid-item ${gridClass} ${
					!disableImage && !videoThumb && !image
						? isPressViews && !enforceNoMedia
							? ''
							: 'post-grid-item--nomedia'
						: ''
				}`}
			>
				<Link
					href={story.link}
					itemProp="url"
					aria-label={story.title}
					className="sb-card-grid-item__link"
					locale={false}
				>
					<>
						{!disableImage && !videoThumb && image && (
							<figure
								className="sb-card-grid-item__image-fit sb-image-fit"
								itemProp="image"
								itemScope
								itemType="http://schema.org/ImageObject"
							>
								<div className="sb-card-grid-item__image-contain sb-image-fit__image-contain">
									<Image
										src={image.src}
										alt={image.alt}
										placeholder="blur"
										blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVR42mNkTnKsZyACMI4qpK9CAPyfC31pseXRAAAAAElFTkSuQmCC"
										layout="fill"
										objectFit="cover"
										sizes="(min-width: 992px) 33vw, (min-width: 576px) 50vw, 100vw"
										quality={100}
									/>
								</div>
							</figure>
						)}

						{videoThumb && (
							<div className="sb-card-grid-item__image-fit sb-image-fit">
								<VideoThumbnail
									fileSrc={videoThumb}
									parentClass="sb-card-grid-item__image-contain sb-image-fit__image-contain"
								/>
							</div>
						)}

						<div className={`sb-card-grid-item__title-contain${titleContainClass}`}>
							{primaryTerm && (
								<div className="sb-card-grid-item__meta-cat sb-card-grid-item__meta-cat--narrow">
									<span className="sb-card-grid-item__meta-cat-text">
										{primaryTerm.name}
									</span>
									<span
										className="sb-card-grid-item__meta-cat-text sb-card-grid-item__meta-cat-text-dummy"
										aria-hidden="true"
									>
										{primaryTerm.name}
									</span>
								</div>
							)}

							<RawHTML
								tagName="h3"
								className={`sb-card-grid-item__title${titleClass}`}
								value={story.title}
							/>

							{!hideDate && this.postDate()}
						</div>
					</>
				</Link>
			</div>
		);
	}
}

export default PostCard;
